// App.js
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ParentComponent from './ParentComponent';
import QuestionPage from './QuestionPage';
import ReportPage from './ReportPage';
import QueryComponent from './QueryComponent'; // Make sure to import your QueryInterface component

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ParentComponent />} />
        <Route path="/question" element={<QuestionPage />} />
        <Route path="/report" element={<ReportPage />} />
        <Route path="/queries" element={<QueryComponent />} /> {/* Add this line for the QueryInterface route */}
      </Routes>
    </Router>
  );
}

export default App;
