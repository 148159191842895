import React, { useState } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2'; // Assuming you want to use a Bar chart. You can change this based on your needs.

function QueryInterface() {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState(null);
  const [chartData, setChartData] = useState({});

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('/api/queries', { query });
      setResults(response.data); // Assuming this is your raw data from the backend

      // Transform your results into chart data here
      const dataForChart = {
        labels: response.data.map(item => item.label), // Adjust 'item.label' based on your data structure
        datasets: [
          {
            label: 'Results', // You can name this according to what your data represents
            data: response.data.map(item => item.value), // Adjust 'item.value' based on your data structure
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              // Add more colors as needed
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              // Add more colors as needed
            ],
            borderWidth: 1,
          },
        ],
      };

      setChartData(dataForChart);
    } catch (error) {
      console.error('Failed to fetch results:', error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={query}
          onChange={handleQueryChange}
          placeholder="Type your query..."
        />
        <button type="submit">Submit</button>
      </form>
      <div>
        {results && <pre>{JSON.stringify(results, null, 2)}</pre>}
        {chartData && chartData.labels && chartData.labels.length > 0 && <Bar data={chartData} />}
      </div>
    </div>
  );
}

export default QueryInterface;

