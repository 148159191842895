// ParentComponent.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import HomePage from './HomePage'; // Assuming HomePage is in the same directory

const ParentComponent = () => {
  const navigate = useNavigate();

  const handleUrlSubmit = (url) => {
    // Navigate to QuestionPage, passing the URL as a query parameter
    navigate(`/question?url=${encodeURIComponent(url)}`);
  };

  return <HomePage onUrlSubmit={handleUrlSubmit} />;
};

export default ParentComponent;
