import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import LoadingPopup from './LoadingPopup';
import './QuestionPage.css';

const QuestionSlider = ({ label, description, value, setValue, isChecked, setIsChecked }) => {
  const handleSliderChange = (e) => {
    setValue(parseFloat(e.target.value));
    if (!isChecked) {
      setIsChecked(true);
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="question">
      <label>{label}</label>
      <div className={`slider-container ${isChecked ? 'checked' : ''}`}>
        <input
          type="checkbox"
          className={`slider-checkbox ${isChecked ? 'checked' : ''}`}
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <input
          type="range"
          min="0"
          max="10"
          value={value}
          onChange={handleSliderChange}
          className={`slider ${isChecked ? 'checked' : ''}`}
          disabled={!isChecked}
        />
        <div className="slider-value">{value}</div>
      </div>
      <div className="question-description">{description}</div>
    </div>
  );
};

const QuestionPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const oppName = location.state?.oppName;
  const analysisData = location.state?.analysisData;
  console.log("Received analysis data:", analysisData); // This line goes right after the definition of analysisData
  const companyURL = location.state?.companyURL;
  const companyName = analysisData?.companyName || 'the Company'; // Default to 'the Company' if not found


  console.log(analysisData);
  console.log(analysisData?.advice); // This helps to ensure the advice key exists and contains data.

  const [alignment, setAlignment] = useState(5);
  const [alignmentChecked, setAlignmentChecked] = useState(false);
  const [companySize, setCompanySize] = useState(5);
  const [companySizeChecked, setCompanySizeChecked] = useState(false);
  const [decisionMakerEngagement, setDecisionMakerEngagement] = useState(5);
  const [decisionMakerEngagementChecked, setDecisionMakerEngagementChecked] = useState(false);
  const [budgetAvailability, setBudgetAvailability] = useState(5);
  const [budgetAvailabilityChecked, setBudgetAvailabilityChecked] = useState(false);
  const [urgency, setUrgency] = useState(5);
  const [urgencyChecked, setUrgencyChecked] = useState(false);
  const [solutionFit, setSolutionFit] = useState(5);
  const [solutionFitChecked, setSolutionFitChecked] = useState(false);
  const [relationshipQuality, setRelationshipQuality] = useState(5);
  const [relationshipQualityChecked, setRelationshipQualityChecked] = useState(false);
  const [communications, setCommunications] = useState(5);
  const [communicationsChecked, setCommunicationsChecked] = useState(false);
  const [clientResponseTime, setClientResponseTime] = useState(5);
  const [clientResponseTimeChecked, setClientResponseTimeChecked] = useState(false);
  const [currentDealChallenges, setCurrentDealChallenges] = useState('');
  const [industry, setIndustry] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [primaryContactJobTitle, setPrimaryContactJobTitle] = useState(''); // State for primary contact's job title

  const renderAnalysisResponse = () => {
  // Ensure analysisData and analysisData.advice are defined before attempting to split
  if (!analysisData || !analysisData.advice) return <p>No analysis data available.</p>;

  const sections = analysisData.advice.split('\n\n').map((section, index) => {
    const lines = section.split('\n');
    const header = lines[0];
    const content = lines.slice(1);
    return (
      <div key={index} className="response-section">
        <h3>{header}</h3>
        <ul>
          {content.map((line, lineIndex) => (
            <li key={lineIndex}>{line}</li>
          ))}
        </ul>
      </div>
    );
  });

  return <div className="analysis-response">{sections}</div>;
};

  const handleFormSubmit = async (event) => {
  event.preventDefault();
  setIsLoading(true);

  const checkedQuestions = [
    alignmentChecked ? 'alignment' : '',
    companySizeChecked ? 'company_size' : '',
    decisionMakerEngagementChecked ? 'decision_maker_engagement' : '',
    budgetAvailabilityChecked ? 'budget_availability' : '',
    urgencyChecked ? 'urgency' : '',
    solutionFitChecked ? 'solution_fit' : '',
    relationshipQualityChecked ? 'relationship_quality' : '',
    communicationsChecked ? 'communications' : '',
    clientResponseTimeChecked ? 'client_response_time' : '',
  ].filter(Boolean);

  // Create an object to hold the opportunity data
  const opportunityData = {
    opportunity: {
      alignment: { value: alignment, checked: alignmentChecked },
      company_size: { value: companySize, checked: companySizeChecked },
      decision_maker_engagement: { value: decisionMakerEngagement, checked: decisionMakerEngagementChecked },
      budget_availability: { value: budgetAvailability, checked: budgetAvailabilityChecked },
      urgency: { value: urgency, checked: urgencyChecked },
      solution_fit: { value: solutionFit, checked: solutionFitChecked },
      relationship_quality: { value: relationshipQuality, checked: relationshipQualityChecked },
      communications: { value: communications, checked: communicationsChecked },
      client_response_time: { value: clientResponseTime, checked: clientResponseTimeChecked },
      primary_contact_job_title: primaryContactJobTitle,
      deal_challenges: currentDealChallenges,
      companyName: companyName, // Directly use the state variable if it's named companyName
      industry: industry, // Directly use the state variable if it's named industry
      jobTitle: primaryContactJobTitle, // If primaryContactJobTitle holds the job title info
    },
    checkedQuestions: checkedQuestions,
    // Include the analysis data directly in the opportunityData object
    analysisData: analysisData,
  };

  // Optionally include companyURL in the opportunity data
  if (companyURL) {
    opportunityData.opportunity.companyURL = companyURL;
  }
  console.log("Submitting opportunity data:", opportunityData); // This line goes right before the try block
  try {
    const response = await axios.post('https://oppgraderback-064ea207d3e3.herokuapp.com/api/opportunities', opportunityData);

    if (response.status === 200) {
      navigate('/report', { state: { reportData: response.data, oppName } });
    } else {
      console.error('Error submitting form:', response);
    }
  } catch (error) {
    console.error('Error submitting form:', error);
  } finally {
    setIsLoading(false);
  }
};
  return (
    <div className="report-container">
      <div className="left-column">
        <div className="logo-container">
          <img src="/fundz-logo-white.svg" alt="Fundz Logo" />
        </div>
        <div className="oppgrader-title">OppGrade</div>
        <div className="placeholder-text">
          <p>Fundz's OppGrade™ helps you plan your next move on all your B2B sales opportunities.</p>
          <p>Simply tell OppGrade a little about a lead or opp and then move the sliders for any of the questions you'd like to include in the opportunity analysis. OppGrade will then provide feedback on how to best advance the lead or close the deal.</p>
        </div>
      </div>
       <div className="main-content">
        {/* Dynamic Header with Company Name */}
        <div className="analysis-response">
        <h1>Company Analysis</h1>
        {renderAnalysisResponse()}
        </div>
        {/* New Section Heading */}
        <h2>Advancing the Opportunity</h2> {/* This is the new section heading */}
        <form onSubmit={handleFormSubmit}>
          <div className="additional-info">
          <div className="text-area-container">
              <label htmlFor="primary-contact-job-title">Primary Contact's Job Title:</label>
        <input
          id="primary-contact-job-title"
          placeholder="Enter the primary contact's job title"
          value={primaryContactJobTitle}
          onChange={(e) => setPrimaryContactJobTitle(e.target.value)}
          type="text"
          className="custom-input"
    />
  </div>
            <div className="text-area-container">
              <label htmlFor="deal-challenges"></label>
              <textarea
                id="deal-challenges"
                placeholder="In your own words, provide some information about the lead or opportunity that you'd like help with, such as current challenges, goals, background info, etc."
                value={currentDealChallenges}
                onChange={(e) => setCurrentDealChallenges(e.target.value)}
                rows={4}
                className="custom-textarea"
              />
            </div>
          </div>
          <p></p>
          <div className="message-above-sliders">
            <p><i>*To include any of the items below in your assessment report, just check the box and move the slider to your answer, from 0 to 10.</i></p>
          </div>
          <div className="question-columns">
            <div className="column cps">
              <h3>Customer Profile Score (CPS)</h3>
              <QuestionSlider
                label="Alignment"
                description="How closely aligned is the customer's industry with your product/service?"
                value={alignment}
                setValue={setAlignment}
                isChecked={alignmentChecked} // Added isChecked prop
                setIsChecked={setAlignmentChecked} // Added setIsChecked prop
              />
              <QuestionSlider
                label="Company Size"
                description="Does the company size match your target market?"
                value={companySize}
                setValue={setCompanySize}
                isChecked={companySizeChecked} // Added isChecked prop
                setIsChecked={setCompanySizeChecked} // Added setIsChecked prop
              />
              <QuestionSlider
                label="Decision Maker Engagement"
                description="Level of engagement from the decision maker(s)."
                value={decisionMakerEngagement}
                setValue={setDecisionMakerEngagement}
                isChecked={decisionMakerEngagementChecked} // Added isChecked prop
                setIsChecked={setDecisionMakerEngagementChecked} // Added setIsChecked prop
              />
            </div>
            <div className="column ods">
              <h3>Opportunity Details Score (ODS)</h3>
              <QuestionSlider
                label="Budget Availability"
                description="Does the client have a budget allocated?"
                value={budgetAvailability}
                setValue={setBudgetAvailability}
                isChecked={budgetAvailabilityChecked} // Added isChecked prop
                setIsChecked={setBudgetAvailabilityChecked} // Added setIsChecked prop
              />
              <QuestionSlider
                label="Urgency"
                description="How urgently does the client need your solution?"
                value={urgency}
                setValue={setUrgency}
                isChecked={urgencyChecked} // Added isChecked prop
                setIsChecked={setUrgencyChecked} // Added setIsChecked prop
              />
              <QuestionSlider
                label="Solution Fit"
                description="How well does your product/service meet the client's needs?"
                value={solutionFit}
                setValue={setSolutionFit}
                isChecked={solutionFitChecked} // Added isChecked prop
                setIsChecked={setSolutionFitChecked} // Added setIsChecked prop
              />
            </div>
            <div className="column sis">
              <h3>Sales Interaction Score (SIS)</h3>
              <QuestionSlider
                label="Relationship Quality"
                description="Quality of the relationship with the client."
                value={relationshipQuality}
                setValue={setRelationshipQuality}
                isChecked={relationshipQualityChecked} // Added isChecked prop
                setIsChecked={setRelationshipQualityChecked} // Added setIsChecked prop
              />
              <QuestionSlider
                label="Communications"
                description="Frequency and tone of communications with the client."
                value={communications}
                setValue={setCommunications}
                isChecked={communicationsChecked} // Added isChecked prop
                setIsChecked={setCommunicationsChecked} // Added setIsChecked prop
              />
              <QuestionSlider
                label="Client Response Time"
                description="How quickly does the client respond to communications?"
                value={clientResponseTime}
                setValue={setClientResponseTime}
                isChecked={clientResponseTimeChecked} // Added isChecked prop
                setIsChecked={setClientResponseTimeChecked} // Added setIsChecked prop
              />
            </div>
          </div>
          <button type="submit" className="submit-button">
            Submit
          </button>
        </form>
      </div>
      <LoadingPopup isLoading={isLoading} />
    </div>
  );
};

export default QuestionPage;
