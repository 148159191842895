import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import GaugeChart from 'react-gauge-chart';
import './reportpage.css';

const ReportPage = () => {
  const location = useLocation();

  const [reportData, setReportData] = useState({});
  const [oppName, setOppName] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataFromLocation = location.state?.reportData;
        const oppNameFromLocation = location.state?.oppName;
        if (!dataFromLocation) {
          throw new Error('Missing report data in location state');
        }
        console.log('Received report data:', dataFromLocation);
        setReportData(dataFromLocation); // Directly setting the data from location.state
        setOppName(oppNameFromLocation);
        setError(null);
      } catch (error) {
        console.error('Error fetching report data:', error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [location]);

  const formatAdviceText = (adviceText) => (
    adviceText.split('\n').map((line, index) => <p key={index}>{line}</p>)
  );

  return (
    <div className="report-container">
      <div className="left-column">
        <div className="logo-container">
          <img src="/fundz-logo-white.svg" alt="Fundz Logo" />
        </div>
        <div className="oppgrader-title">OppGrade</div>
        <div className="placeholder-text">
          <p>Fundz's OppGrade™ helps you plan your next move on all your B2B sales opportunities.</p>
          <p>Simply move the slider for each question to the appropriate number, then when you're done with all the questions, just click the submit button.</p>
          <p>Using our proprietary, weighted scoring algorithm, the next page will provide feedback on each of your answers, plus a total score of the opportunity.</p>
          <p>Leverage OppGrader for all your deals, you can spend your time more wisely and win more deals!</p>
        </div>
      </div>
      <main className="main-content">
        {isLoading ? (
          <div className="loading-indicator">Loading report...</div>
        ) : error ? (
          <div className="error-message">{error.message}</div>
        ) : (
          <>
            <header>
              <h1>Opportunity Assessment Report for {oppName || 'Unknown Opportunity'}</h1>
            </header>
            <div className="advice-section">
              {reportData.advice && <div className="advice-content">{formatAdviceText(reportData.advice)}</div>}
            </div>
            <div className="deal-challenges-section">
              {reportData.dealChallenges && <p>{reportData.dealChallenges}</p>}
            </div>
            <div className="cards-container">
              {Array.isArray(reportData.checkedQuestions) && reportData.checkedQuestions.map((question) => {
                const score = reportData.scores?.[question];
                if (score !== null && score !== undefined) {
                  const title = question.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
                  return (
                    <div className="feedback-card" key={question}>
                      <h3>{title}</h3>
                      <GaugeChart
                        id={`gauge-chart-${question}`}
                        numberOfLevels={20}
                        percent={score / 10}
                        textColor="#000000"
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </>
        )}
      </main>
    </div>
  );
};

export default ReportPage;
