import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const HomePage = () => {
  const [companyURL, setCompanyURL] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const formatURL = (url) => {
    if (!url) return '';
    if (url.startsWith('http://www.') || url.startsWith('https://www.')) {
      return url;
    } else if (url.startsWith('www.')) {
      return `https://${url}`;
    } else if (url.startsWith('http://')) {
      return url.replace('http://', 'https://www.');
    } else if (url.startsWith('https://')) {
      return url.replace('https://', 'https://www.');
    } else {
      return `https://www.${url}`;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    const formattedURL = formatURL(companyURL);

    if (!formattedURL) {
      setError('Please enter a valid URL.');
      return;
    }

    try {
      // Make the corrected Axios request using formattedURL
      const response = await axios.post('https://oppgraderback-064ea207d3e3.herokuapp.com/api/website_analysis', { url: formattedURL });

      // Log the response data and navigate to the question page with analysis data and opportunity name
      console.log({ analysisData: response.data, oppName: 'Your Opportunity' });
      navigate('/question', { state: { analysisData: response.data, oppName: 'Your Opportunity' } });
    } catch (err) {
      console.error('Error submitting URL for analysis:', err);
      setError('Failed to process the URL. Error: ' + err.message);
    }
  };

  return (
    <div className="home-container">
      <div className="logo-container">
        <img src={process.env.PUBLIC_URL + "/fundzlogo.svg"} alt="OppGrade Logo" className="logo" />
      </div>
      <h1 className="title">OppGrade</h1>
      <p className="subtitle">
        Start creating and closing more deals today with OppGrade.
      </p>
      <form onSubmit={handleSubmit} className="grader-form">
        <input
          type="text"
          value={companyURL}
          onChange={(e) => setCompanyURL(e.target.value)}
          placeholder="Enter company URL"
          className="input-field"
          aria-label="Company URL"
        />
        <button type="submit" className="submit-btn">
          Analyze
        </button>
      </form>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default HomePage;
