import React from 'react';
import './LoadingPopup.css';

const LoadingPopup = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className="Loading-Popup">
      <div className="loading-spinner"></div>
      <p>Loading your report.. 🚀</p>
    </div>
  );
};

export default LoadingPopup;